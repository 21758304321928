@use 'variables' as var;

// stylelint-disable property-no-vendor-prefix

.mat-bottom-sheet-container {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  min-width: var.$vwWidth !important;
  width: var.$vwWidth !important;
}

.wiz-bottom-sheet {
  touch-action: auto !important;
  -webkit-user-select: auto !important;
  -webkit-touch-callout: default !important;
  -webkit-user-drag: auto !important;
  transition: transform 100ms ease;
  box-shadow: 0 0.6875rem 1.25rem rgb(47 45 158 / 16%);

  &.closed {
    transition: transform 375ms cubic-bezier(0.4, 0, 1, 1);
    transform: translateY(100%) !important;
  }

  &.hide {
    opacity: 0;
  }
}
