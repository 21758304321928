@use 'variables' as var;

// stylelint-disable selector-max-specificity

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: var.$blueBg !important;
  color: var.$primary;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.125rem 0.625rem;
  font-weight: normal;
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .mdc-evolution-chip__cell--trailing {
    opacity: 1 !important;
  }

  &:not(.mdc-evolution-chip--disabled) {
    .mdc-evolution-chip__icon--trailing,
    .mdc-evolution-chip__text-label {
      color: var.$primary;
    }
  }

  .mat-icon {
    height: auto;
  }

  &[center] {
    .mat-chip-list-wrapper {
      justify-content: center;
    }
  }
}
