@use 'variables' as var;

.cal-event {
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  border-left-width: 5px !important;
  box-shadow: (0 0.125rem 1.25rem rgb(47 45 158 / 16%)) !important;
  display: flex !important;
  padding: 0 1rem !important;
  align-items: center !important;
  height: calc(100% - 1rem) !important;
  margin: 0.5rem 0 !important;
  text-decoration: none;
  border-radius: 12px !important;

  &__content {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
    justify-content: space-between;

    &__left {
      max-width: 75%;
    }
  }
}

.cal-day-column {
  background-color: var.$mainBg;
}

.cal-hour-segment {
  border-bottom: 1px solid #e9e9ea !important;

  &:hover {
    background-color: transparent !important;
  }
}

.cal-week-view {
  .cal-time-events {
    border: none !important;
  }

  .cal-time {
    padding: 0;
    transform: translateY(-50%);
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.5;
    background-color: var.$mainBg;
  }

  /* stylelint-disable-next-line selector-max-specificity */
  .cal-hour-segment.cal-after-hour-start .cal-time {
    display: block;
  }
}

.cal-event-title,
.cal-event-subtitle {
  line-height: 1.5;
}

.cal-event-title {
  color: #3b3447;
  font-weight: bold;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cal-event-subtitle {
  font-size: 0.875rem;
  color: #74767b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
