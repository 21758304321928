@use 'variables' as var;

.wzb-toggle-button {
  transition: background-color 200ms ease-in-out;
}

.toggle--warning {
  .wzb-toggle-button {
    background-color: var.$warning !important;
  }
}
