// stylelint-disable selector-no-qualifying-type

@use 'variables' as var;

%btn {
  padding: 0.75rem 1.875rem;
  border: 1px solid var.$primary;
  border-radius: 100px;
  text-decoration: none;
  background-color: var.$primary;
  color: var.$light;
  box-shadow: 0 0.625rem 1.25rem rgb(104 75 75 / 15%);
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  overflow-anchor: none;
  cursor: pointer;
}

.btn {
  @extend %btn;

  &--caution {
    @extend %btn;

    background-color: var.$error;
  }

  &--classic {
    @extend %btn;

    background-color: var.$blueBg;
    color: var.$black;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.btn-clean {
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  appearance: none;
  overflow-anchor: none;
}

a.btn {
  display: inline-block;
}
