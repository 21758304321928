.animate-on-header-stuck {
  z-index: 3;
  transition: opacity 0.2s ease-out;

  &--isStuck {
    animation: stuck 0.5s linear alternate;
  }
}

@keyframes stuck {
  0% {
    z-index: 3;
    opacity: 1;
  }

  25% {
    z-index: 1;
    opacity: 0;
  }

  100% {
    z-index: 1;
    opacity: 1;
  }
}
