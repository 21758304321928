// stylelint-disable unit-disallowed-list

@mixin handset() {
  @media (width <= 37.4988rem) and (orientation: portrait), (width <= 59.9988rem) and (orientation: landscape) {
    @content;
  }
}

@mixin handsetPortrait() {
  @media (width <= 37.4988rem) and (orientation: portrait) {
    @content;
  }
}

@mixin handsetLandscape() {
  @media (width <= 59.9988rem) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet() {
  @media (37.5rem <= width <= 52.4988rem) and (orientation: portrait),
    (60rem <= width <= 79.9988rem) and (orientation: landscape) {
    @content;
  }
}

@mixin tabletPortrait() {
  @media (37.5rem <= width <= 52.4988rem) and (orientation: portrait) {
    @content;
  }
}

@mixin tabletLandscape() {
  @media (60rem <= width <= 79.9988rem) and (orientation: landscape) {
    @content;
  }
}

@mixin web() {
  @media (width >= 52.5rem) and (orientation: portrait), (width >= 80rem) and (orientation: landscape) {
    @content;
  }
}

@mixin largeTabletPortrait() {
  @media (64rem <= width <= 85.375rem) and (orientation: portrait) and (resolution >= 2dppx) {
    @content;
  }
}

@mixin largeTabletLandscape() {
  @media only screen and (64rem <= width <= 85.375rem) and (resolution >= 2dppx) and (orientation: landscape) {
    @content;
  }
}

@mixin webOrTabletLandscape() {
  @media (60rem <= width <= 79.9994rem) and (orientation: landscape),
    (width >= 52.5rem) and (orientation: portrait),
    (width >= 80rem) and (orientation: landscape) {
    @content;
  }
}

@mixin notHandset() {
  @media (37.5rem <= width <= 52.4988rem) and (orientation: portrait),
    (60rem <= width <= 79.9988rem) and (orientation: landscape),
    (width >= 52.5rem) and (orientation: portrait),
    (width >= 80rem) and (orientation: landscape) {
    @content;
  }
}

@mixin tabletOrLargeTabletPortrait() {
  @media (64rem <= width <= 85.375rem) and (orientation: portrait) and (resolution >= 2dppx),
    (37.5rem <= width <= 52.4988rem) and (orientation: portrait),
    (60rem <= width <= 79.9988rem) and (orientation: landscape) {
    @content;
  }
}

@mixin webNotIpadPro() {
  @media (width >= 64.0625rem) and (orientation: portrait), (width >= 80rem) and (orientation: landscape) {
    @content;
  }
}

// stylelint-disable media-feature-name-no-vendor-prefix
@mixin ipadPro() {
  @media only screen and (width >= 64rem) and (height <= 85.375rem) and (-webkit-min-device-pixel-ratio: 1.5) {
    @content;
  }
}
