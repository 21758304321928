$primary: #6a5eff;
$secondary: purple;
$mainBg: #f9f9fd;
$blueBg: #f0efff;
$success: #56c992;
$error: #dd4a3f;
$warning: #f1bd38;
$yellow: #c28831;
$light: white;
$lightGrey: #ebebed;
$darkBlue: #4542fe;
$lightBlue: #f8f7ff;
$lightYellow: #fdf4e2;
$grey: #807a8a;
$font-family: 'Cera Pro', sans-serif;
$black: #0d1c2e;
$lightBlack: #3b324b;
$textColor: $lightBlack;
$vwWidth: calc(100vw - env(safe-area-inset-right, 0) - env(safe-area-inset-left, 0));
$percentWidth: calc(100% - env(safe-area-inset-right, 0) - env(safe-area-inset-left, 0));
$mainPadding: 1.25rem;
$defaultLineHeight: 1.5;
$navHeight: 4rem;
$headerHeightSmall: 7.5625rem;
$headerHeightMedium: 7.5625rem;
$headerHeightLarge: 9.5rem;
$iconSizeBasic: 2.5rem;
