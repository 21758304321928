@use 'variables' as var;
@use 'breakpoints' as br;
@use 'theme';
@use 'btn';
@use 'header-stuck';
@use 'override/calendar';
@use 'override/material';
@use 'override/wizbii-component';
@use '@wizbii-components/angular-styles/src/modules/wzb-theming/wzb-toggle' as toggle;
@use '@wizbii-components/styles/src/utils/visually-hidden' as visuallyHidden;

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-Light.eot');
  src: local('Cera Pro Light'), local('CeraPro-Light'),
    url('/assets/fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-Light.woff2') format('woff2'), url('/assets/fonts/CeraPro-Light.woff') format('woff'),
    url('/assets/fonts/CeraPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-MediumItalic.eot');
  src: local('Cera Pro Medium Italic'), local('CeraPro-MediumItalic'),
    url('/assets/fonts/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/CeraPro-MediumItalic.woff') format('woff'),
    url('/assets/fonts/CeraPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-RegularItalic.eot');
  src: local('Cera Pro Regular Italic'), local('CeraPro-RegularItalic'),
    url('/assets/fonts/CeraPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-RegularItalic.woff2') format('woff2'),
    url('/assets/fonts/CeraPro-RegularItalic.woff') format('woff'),
    url('/assets/fonts/CeraPro-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-BlackItalic.eot');
  src: local('Cera Pro Black Italic'), local('CeraPro-BlackItalic'),
    url('/assets/fonts/CeraPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-BlackItalic.woff2') format('woff2'),
    url('/assets/fonts/CeraPro-BlackItalic.woff') format('woff'),
    url('/assets/fonts/CeraPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-Medium.eot');
  src: local('Cera Pro Medium'), local('CeraPro-Medium'),
    url('/assets/fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-Medium.woff2') format('woff2'), url('/assets/fonts/CeraPro-Medium.woff') format('woff'),
    url('/assets/fonts/CeraPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-BoldItalic.eot');
  src: local('Cera Pro Bold Italic'), local('CeraPro-BoldItalic'),
    url('/assets/fonts/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/CeraPro-BoldItalic.woff') format('woff'),
    url('/assets/fonts/CeraPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-ThinItalic.eot');
  src: local('Cera Pro Thin Italic'), local('CeraPro-ThinItalic'),
    url('/assets/fonts/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-ThinItalic.woff2') format('woff2'),
    url('/assets/fonts/CeraPro-ThinItalic.woff') format('woff'),
    url('/assets/fonts/CeraPro-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-LightItalic.eot');
  src: local('Cera Pro Light Italic'), local('CeraPro-LightItalic'),
    url('/assets/fonts/CeraPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/CeraPro-LightItalic.woff') format('woff'),
    url('/assets/fonts/CeraPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-Black.eot');
  src: local('Cera Pro Black'), local('CeraPro-Black'),
    url('/assets/fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-Black.woff2') format('woff2'), url('/assets/fonts/CeraPro-Black.woff') format('woff'),
    url('/assets/fonts/CeraPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-Bold.eot');
  src: local('Cera Pro Bold'), local('CeraPro-Bold'),
    url('/assets/fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-Bold.woff2') format('woff2'), url('/assets/fonts/CeraPro-Bold.woff') format('woff'),
    url('/assets/fonts/CeraPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-Regular.eot');
  src: local('Cera Pro Regular'), local('CeraPro-Regular'),
    url('/assets/fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-Regular.woff2') format('woff2'), url('/assets/fonts/CeraPro-Regular.woff') format('woff'),
    url('/assets/fonts/CeraPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/assets/fonts/CeraPro-Thin.eot');
  src: local('Cera Pro Thin'), local('CeraPro-Thin'),
    url('/assets/fonts/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/CeraPro-Thin.woff2') format('woff2'), url('/assets/fonts/CeraPro-Thin.woff') format('woff'),
    url('/assets/fonts/CeraPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

html {
  color: var.$textColor;
  background-color: var.$mainBg;
  font-family: var.$font-family;
  font-weight: normal;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  overflow: visible !important;
  margin: auto;
  max-width: var.$vwWidth;
  height: 100vh;

  @include br.web {
    // stylelint-disable-next-line unit-disallowed-list
    max-width: 768px;
    margin: auto;
  }
}

body {
  margin: 0;
  padding-right: 0 !important;
  overflow-x: hidden;
  color: var.$textColor;
  background-color: var.$mainBg;
  height: 100vh;

  @include br.web {
    // stylelint-disable-next-line unit-disallowed-list
    max-width: 768px;
    margin: auto;
    position: relative;
  }
}

code {
  white-space: pre-wrap;
}

*:focus {
  outline: var.$primary auto 0.0625rem;
  outline-offset: 0.3125rem;
}

p {
  margin: 0;
}

.mat-focused {
  input {
    outline: var.$primary auto 0.0625rem;
    outline-offset: 0.3125rem;
  }

  // stylelint-disable selector-type-no-unknown
  mat-select {
    outline: var.$primary auto 0.0625rem;
    outline-offset: 0.3125rem;
  }
}

.ul-clean {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.btn-clean {
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  appearance: none;
}

.accessibility-off {
  *:focus {
    outline: none !important;
  }
}

.sticky-header-sentinel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 2.5rem;
  visibility: hidden;
}

@include toggle.wzbToggle(var.$success, var.$secondary, var.$lightGrey);
@include visuallyHidden.visually-hidden;
